var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [!_vm.disableViewPATRA ? _c('b-col', {
    attrs: {
      "lg": "8",
      "md": "12",
      "cols": "12"
    }
  }, [_vm.getIsLoading('PATRA') ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "success"
    }
  }, [_c('b-progress-bar', {
    attrs: {
      "value": 100
    }
  }, [_c('strong', [_vm._v("Sedang memuat data")])])], 1) : _vm._e(), _c('landgraph-kendaraan', {
    key: _vm.renderPatraMonth,
    attrs: {
      "groupid": "2",
      "name": "PATRA",
      "is-previous": _vm.isPreviousPatra,
      "option-data": _vm.getOptionTime('Monthpatra'),
      "today-total-value": _vm.getTodayTotalValue('PATRA'),
      "prev-today-total-value": _vm.getPrevTodayTotalValue('PATRA')
    },
    on: {
      "custom-event": _vm.handleCustomEvent
    }
  })], 1) : _vm._e(), !_vm.disableViewPATRA ? _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "12",
      "cols": "12"
    }
  }, [_vm.getTimeIsLoading('PATRA') ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "success"
    }
  }, [_c('b-progress-bar', {
    attrs: {
      "value": 100
    }
  }, [_c('strong', [_vm._v("Sedang memuat data")])])], 1) : _vm._e(), _c('landgraph-kendaraan-time', {
    key: _vm.renderPatra,
    attrs: {
      "groupid": "2",
      "name": "PATRA",
      "today-value": _vm.todayValue,
      "option-data": _vm.getOptionTime('patra')
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }