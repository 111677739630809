<template>
  <b-row class="match-height">
    <b-col lg="8" md="12" cols="12" v-if="!disableViewPATRA">
      <b-progress
        v-if="getIsLoading('PATRA')"
        key="info"
        animated
        value="100"
        variant="success"
        :class="'progress-bar-info'"
      >
        <b-progress-bar :value="100">
          <strong>Sedang memuat data</strong>
        </b-progress-bar>
      </b-progress>

      <landgraph-kendaraan
        :key="renderPatraMonth"
        groupid="2"
        name="PATRA"
        :is-previous="isPreviousPatra"
        @custom-event="handleCustomEvent"
        :option-data="getOptionTime('Monthpatra')"
        :today-total-value="getTodayTotalValue('PATRA')"
        :prev-today-total-value="getPrevTodayTotalValue('PATRA')"
      />
    </b-col>
    <b-col lg="4" md="12" cols="12" v-if="!disableViewPATRA">
      <b-progress
        v-if="getTimeIsLoading('PATRA')"
        key="info"
        animated
        value="100"
        variant="success"
        :class="'progress-bar-info'"
      >
        <b-progress-bar :value="100">
          <strong>Sedang memuat data</strong>
        </b-progress-bar>
      </b-progress>
      <landgraph-kendaraan-time
        :key="renderPatra"
        groupid="2"
        name="PATRA"
        :today-value="todayValue"
        :option-data="getOptionTime('patra')"
      />
    </b-col>
    <!-- <b-col lg="8" md="12" cols="12" v-if="!disableViewTEM">
      <b-progress
        v-if="getIsLoading('TEM')"
        key="info"
        animated
        value="100"
        variant="success"
        :class="'progress-bar-info'"
      >
        <b-progress-bar :value="100">
          <strong>Sedang memuat data</strong>
        </b-progress-bar>
      </b-progress>
      <landgraph-kendaraan
        :key="renderTemMonth"
        groupid="1"
        name="TEM"
        :is-previous="isPreviousTem"
        @custom-event="handleCustomEvent"
        :option-data="getOptionTime('Monthtem')"
        :today-total-value="getTodayTotalValue('TEM')"
        :prev-today-total-value="getPrevTodayTotalValue('TEM')"
      />
    </b-col>
    <b-col lg="4" md="12" cols="12" v-if="!disableViewTEM">
      <b-progress
        v-if="getTimeIsLoading('TEM')"
        key="info"
        animated
        value="100"
        variant="success"
        :class="'progress-bar-info'"
      >
        <b-progress-bar :value="100">
          <strong>Sedang memuat data</strong>
        </b-progress-bar>
      </b-progress>
      <landgraph-kendaraan-time
        :key="renderTem"
        groupid="1"
        name="TEM"
        :today-value="todayValue"
        :option-data="getOptionTime('tem')"
      />
    </b-col> -->
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import LandgraphKendaraanTime from './LandgraphKendaraanTime.vue'
import LandgraphKendaraan from './LandgraphKendaraan.vue'
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    LandgraphKendaraan,
    LandgraphKendaraanTime,
    BRow,
    BCol
  },
  data() {
    return {
      timeoutDebounce: null,
      isLoading: {},
      timeIsLoading: {},

      renderTem: 1,
      renderTemMonth: 3,
      renderPatra: 2,
      renderPatraMonth: 5,
      renders: [1, 1],
      disableViewTEM: true,
      disableViewPATRA: true,
      optionTime: {},
      todayValue: 'Today',
      todayTotalValue: {},
      prevTodayTotalValue: {},
      isPreviousPatra: false,
      isPreviousTem: false
    }
  },
  setup() {
    // defined
    const { dailyVehicle } = useAppConfig()

    return {
      dailyVehicle
    }
  },
  watch: {
    dailyVehicle() {
      this.query(
        this.convertDateFormat(this.dailyVehicle.date),
        `${this.dailyVehicle.endpoint}`
      )
    },
    '$store.state.app.watchNotificationClockIN': function () {
      if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
      this.timeoutDebounce = setTimeout(() => {
        this.init()
      }, 300)
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const userData = getUserData()
      const group = userData.group?.name ?? ''
      const role = userData.role
      if (group === 'TEM') {
        this.disableViewTEM = false
        this.disableViewPATRA = true
        this.query('', 'TEM')
        this.queryMonth(false, 'TEM')
      }
      if (group === 'PATRA') {
        this.disableViewTEM = true
        this.disableViewPATRA = false
        this.query('', 'PATRA')
        this.queryMonth(false, 'PATRA')
      }
      if (['host', 'security'].includes(role)) {
        this.fetchAll()
      }
    },
    fetchAll() {
      this.disableViewTEM = false
      this.disableViewPATRA = false
      this.query('', 'TEM')
      this.query('', 'PATRA')
      this.queryMonth(false, 'TEM')
      this.queryMonth(false, 'PATRA')
    },
    handleCustomEvent(payload) {
      // Handle the custom event from the child component
      // this.messageFromChild = payload;
      if (payload.name.includes('EXCEL')) {
        const state =
          payload.name.split('_')[0] === 'TEM'
            ? this.isPreviousTem
            : this.isPreviousPatra
        this.queryMonth(state, payload.name.split('_')[0], true)
      } else {
        if (payload.name === 'TEM') {
          this.queryMonth(payload.state, 'TEM')
          this.isPreviousTem = payload.state
        } else {
          this.queryMonth(payload.state, 'PATRA')
          this.isPreviousPatra = payload.state
        }
      }
    },
    getOptionTime(groupName) {
      // Check if the optionTime object has the property 'groupName'
      if (this.optionTime.hasOwnProperty(groupName)) {
        return this.optionTime[groupName]
      } else {
        // Return a default object or handle the case when the group name is not found
        return {}
      }
    },
    getIsLoading(groupName) {
      // Check if the optionTime object has the property 'groupName'
      if (this.isLoading.hasOwnProperty(groupName)) {
        return this.isLoading[groupName]
      } else {
        // Return a default object or handle the case when the group name is not found
        return false
      }
    },
    getTimeIsLoading(groupName) {
      // Check if the optionTime object has the property 'groupName'
      if (this.timeIsLoading.hasOwnProperty(groupName)) {
        return this.timeIsLoading[groupName]
      } else {
        // Return a default object or handle the case when the group name is not found
        return false
      }
    },
    getTodayTotalValue(groupName) {
      // Check if the optionTime object has the property 'groupName'
      if (this.todayTotalValue.hasOwnProperty(groupName)) {
        return this.todayTotalValue[groupName]
      } else {
        // Return a default object or handle the case when the group name is not found
        return false
      }
    },
    getPrevTodayTotalValue(groupName) {
      // Check if the optionTime object has the property 'groupName'
      if (this.prevTodayTotalValue.hasOwnProperty(groupName)) {
        return this.prevTodayTotalValue[groupName]
      } else {
        // Return a default object or handle the case when the group name is not found
        return false
      }
    },
    getRender(groupName) {
      // Check if the renders array has the value for 'groupName'
      const index = groupName === 'patra' ? 0 : 1
      if (this.renders[index]) {
        return this.renders[index]
      } else {
        // Return a default value or handle the case when the group name is not found
        return 1
      }
    },
    convertDateFormat(dateString) {
      const [day, month, year] = dateString.split('-')
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
        2,
        '0'
      )}`
    },
    query(clock_in_time, groupName) {
      this.timeIsLoading[`${groupName}`] = true
      console.log('isloading: ', this.timeIsLoading[`${groupName}`])
      useJwt.http
        .get(`vw_lr_${groupName}_vehicle_count_today`, {
          params: { clock_in_time: clock_in_time }
        })
        .then((reports) => {
          const data =
            reports.data[
              `vw_lr_${groupName.toLowerCase()}_vehicle_count_todays`
            ]

          // Extract 'waktu' values using map function
          const xAxisData = data.map((item) => item.waktu)
          const series = data.map((item) => `${item.total}`)

          this.optionTime[groupName.toLowerCase()] = {
            xAxisData: xAxisData,
            series: series
          }
          if (groupName.toLowerCase() === 'tem') {
            this.renderTem += 1
          } else {
            this.renderPatra += 1
          }
          this.todayValue = clock_in_time
          this.timeIsLoading[`${groupName}`] = false
          console.log('isEndloading: ', this.timeIsLoading[`${groupName}`])
        })
    },
    queryMonth(is_previous, groupName, is_excel = false) {
      this.isLoading[`${groupName}`] = true
      let api = `vw_lr_${groupName}_vehicle_count_month`
      if (is_excel) {
        api = `${api}/excel`
      }
      useJwt.http
        .get(api, {
          params: { is_previous: is_previous }
        })
        .then((reports) => {
          if (is_excel) {
            const uri = `${process.env.VUE_APP_BACKEND_URL}/${reports.data.link_file_excel}`
            console.log(uri)
            window.open(uri)
          } else {
            const data =
              reports.data[
                `vw_lr_${groupName.toLowerCase()}_vehicle_count_months`
              ]

            // Extract 'waktu' values using map function
            const xAxisData = data.map((item) => item.waktu)
            const series = data.map((item) => `${item.total}`)

            this.optionTime[`Month` + groupName.toLowerCase()] = {
              xAxisData: xAxisData,
              series: series
            }
            if (groupName.toLowerCase() === 'tem') {
              this.renderTemMonth += 1
            } else {
              this.renderPatraMonth += 1
            }
            const currentDate = new Date().toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit'
            })

            // Find the index of the current date in xAxisData
            const index = xAxisData.indexOf(currentDate.replace(/\//g, '-'))
            const prevTodayValue = series[index - 1]
            const todayValue = series[index]
            this.prevTodayTotalValue[groupName] = prevTodayValue
            this.todayTotalValue[groupName] = todayValue
          }
          this.isLoading[`${groupName}`] = false
        })
    }
  }
}
</script>
