<template>
  <section id="dashboard-landgraph">
    <b-img
      fluid
      :src="imgUrl"
      alt="Reporting River"
      style="position: absolute; right: 0; opacity: 10%; top: 0"
    />

    <b-row class="match-height">
      <b-col lg="5" md="12" cols="12">
        <statistic-card-with-line-chart
          :key="componentKey.statistic"
          icon="TruckIcon"
          color="danger"
          :statistic="rtTruckTodayALL"
          statistic-title=""
          :chart-data="data.statisticsOrder.series"
        />
      </b-col>
      <b-col lg="7" md="12" sm="12" cols="12">
        <!-- realtime check -->
        <b-row class="match-height">
          <b-col lg="4" md="4" cols="12">
            <statistic-card-vertical
              hide-chart
              color="danger"
              icon="TruckIcon"
              :statistic="rtTruckTodayPatraCount"
              :loading="rtTruckTodayLoading"
              statistic-title="PATRA"
              :disable-view-limit="disableViewLimit"
              :max-approvement="maxApprovement['PATRA']"
              :current-approvement="currentApprovement['PATRA']"
            />
          </b-col>
          <!-- <b-col lg4"3"4md="3" cols="12">
            <statistic-card-vertical
              hide-chart
              color="danger"
              icon="TruckIcon"
              :statistic="rtTruckTodayTemCount"
              :loading="rtTruckTodayLoading"
              statistic-title="TEM"
              :disable-view-limit="disableViewLimit"
              :max-approvement="maxApprovement['TEM']"
              :current-approvement="currentApprovement['TEM']"
            />
          </b-col> -->
          <b-col lg="4" md="4" cols="12">
            <statistic-card-vertical
              hide-chart
              color="danger"
              icon="TruckIcon"
              :statistic="rtTruckTodayPilCount"
              :loading="rtTruckTodayLoading"
              statistic-title="PIL"
              :disable-view-limit="disableViewLimit"
              :max-approvement="maxApprovement['PIL']"
              :current-approvement="currentApprovement['PIL']"
            />
          </b-col>
          <b-col lg="4" md="4" cols="12">
            <statistic-card-vertical
              hide-chart
              color="success"
              icon="TruckIcon"
              :statistic="rtTruckTodayHuluCount"
              :loading="rtTruckTodayLoading"
              statistic-title="HULU"
              :disable-view-limit="disableViewLimit"
              :max-approvement="maxApprovement['HULU']"
              :current-approvement="currentApprovement['HULU']"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Truck -->
      <b-col lg="12" md="12" cols="12">
        <b-row class="match-height">
          <b-col lg="4" md="4" cols="12">
            <b-card
              v-if="data.congratulations"
              class="card-congratulation-medal"
            >
              <div v-if="data.congratulations.isHost === true">
                <v-select
                  v-model="hostCondition.ignoreGroup"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="ignoreGroupData"
                  :reduce="(option) => option.value"
                  :selectable="(option) => !option.value.includes('null')"
                  label="text"
                  item-value="value"
                  item-text="text"
                />
                <hr />
                <v-select
                  v-if="hostCondition.ignoreGroup !== 'true'"
                  v-model="hostCondition.loadTypeForm"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="loadTypeFormData"
                  :reduce="(option) => option.value"
                  :selectable="(option) => !option.value.includes('null')"
                  label="text"
                  placeholder="Select Form Type"
                  item-value="value"
                  item-text="text"
                />
                <hr />
                <vue-autosuggest
                  v-if="
                    hostCondition.ignoreGroup !== 'true' &&
                    hostCondition.loadTypeForm
                  "
                  id="vi-group-name"
                  host-condition-group-account-
                  :suggestions="[{ data: groupAccountData }]"
                  :get-suggestion-value="getSuggestionValue"
                  :limit="7"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: 'form-control',
                    placeholder: 'Group Account'
                  }"
                  @input="fetchGroupData($event, 'group/search/')"
                >
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item">{{
                      suggestion.item.name
                    }}</span>
                  </template>
                </vue-autosuggest>
              </div>
              <div v-else>
                <h5>
                  {{
                    $can('manage', 'qrcode') === true
                      ? 'Welcome'
                      : 'Congratulations'
                  }}
                  🎉 {{ data.congratulations.name }}!
                </h5>
                <b-card-text class="font-small-3">
                  {{
                    $can('manage', 'qrcode') === true
                      ? '-'
                      : 'You have make a form in and out.'
                  }}
                </b-card-text>
                <h3 class="mb-75 mt-2 pt-50">
                  <b-link>Keep on safety first</b-link>
                  <!-- <b-link>${{ kFormatter(data.saleToday) }}</b-link> -->
                </h3>
                <b-img
                  :src="require('@/assets/images/illustration/badge.svg')"
                  class="congratulation-medal"
                  alt="Medal Pic"
                />
              </div>
            </b-card>
          </b-col>
          <b-col lg="8" md="8" cols="12">
            <landgraph-order-chart
              :key="componentKey.truck"
              :data="data.statisticsOrder"
              :statistics-land-items="data.statisticsLandItems"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <landgraph-kendaraan-Index />
      </b-col>
      <!-- Revenue Report Card -->
      <b-col lg="12">
        <overlay-loading :loading="loading" />
        <landgraph-revenue-report
          :key="componentKey.qrcode"
          :data="data.revenue"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
  BImg
} from 'bootstrap-vue'

import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import VSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import OverlayLoading from '@views/components/OverlayLoading.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { getUserData } from '@/auth/utils'
import LandgraphStatistics from './LandgraphStatistics.vue'
import LandgraphRevenueReport from './LandgraphRevenueReport.vue'
import LandgraphOrderChart from './LandgraphOrderChart.vue'
import LandgraphKendaraanIndex from './LandgraphKendaraanIndex.vue'
import LandgraphProfitChart from './LandgraphProfitChart.vue'
import useJwt from '@/auth/jwt/useJwt'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { report } from 'process'
import store from '@/store/index'

export default {
  components: {
    LandgraphKendaraanIndex,
    StatisticCardHorizontal,
    VueAutosuggest,
    VSelect,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    StatisticCardWithLineChart,
    LandgraphStatistics,
    LandgraphRevenueReport,
    LandgraphOrderChart,
    LandgraphProfitChart,
    StatisticCardVertical,
    OverlayLoading
  },
  data() {
    return {
      disableViewTEM: true,
      disableViewPATRA: true,
      data: {
        statisticsOrder: {
          series: [
            {
              name: 'Total IN OUT',
              categories: [
                'Senin, 25 Juli',
                'Selasa',
                'Rabu',
                'Kamis',
                'Jumat',
                'Sabtu'
              ],
              data: [45, 85, 65, 45, 65, 77]
            }
          ]
        },
        statisticsProfit: {
          series: [
            {
              name: 'Total IN OUT',
              categories: [
                'Senin, 25 Juli',
                'Selasa',
                'Rabu',
                'Kamis',
                'Jumat',
                'Sabtu'
              ],
              data: [0, 20, 5, 30, 15, 45]
            }
          ]
        }
      },
      loading: false,
      timeoutDebounce: null,
      componentKey: {
        host: 1,
        statistic: 10,
        truck: 20,
        boat: 30,
        qrcode: 40
      },
      hostCondition: {
        ignoreGroup: 'true',
        loadTypeForm: null, // selected
        groupAccount: null
      },
      ignoreGroupData: [
        { value: 'null', text: 'Filter BY' },
        { value: 'true', text: 'ALL' },
        { value: 'false', text: 'Specify' }
      ],
      loadTypeFormData: [
        { value: 'null', text: 'Select Form Type' },
        { value: 'form1', text: 'Form 1  (T1)' },
        { value: 'form2', text: 'Form 2  (T2)' },
        { value: 'form3', text: 'Form 3  (T3)' }
      ],
      groupAccountData: [],
      renderRealtimeTruckSpecific: 1,
      rtTruckTodayPatraCount: [],
      rtTruckTodayTemCount: [],
      rtTruckTodayPilCount: [],
      rtTruckTodayHuluCount: [],
      rtTruckTodayALL: {},
      rtTruckTodayLoading: false,
      downImg: require('@/assets/images/pages/login-v2.svg'),
      disableViewLimit: true,
      maxApprovement: {},
      currentApprovement: {}
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.downImg
      }
      return this.downImg
    }
  },
  watch: {
    '$store.state.app.watchApproval': function (data) {
      // chart monthly dan daily
      console.log('@watch refresh only for organized queue from', data)
      this.organizedQueue()
    },
    '$store.state.app.watchNotificationClockIN': function () {
      // if (this.refSpecify === 'FormType1' || this.refSpecify === 'FormType2' || this.refSpecify === 'FormType3') {
      if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
      this.timeoutDebounce = setTimeout(() => {
        console.log('@watch clock in / clock out fetchTruck data')
        this.fetchRtTruckSpecific() // chart baris 1
      }, 300)
      // }
    },
    '$store.state.app.watchNotification': function () {
      if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
      this.timeoutDebounce = setTimeout(() => {
        console.log('@watch exclude clock in / clock out fetchTruck data')
        this.fetchData() // chart baris 2 dan akhir
      }, 300)
    },

    'hostCondition.ignoreGroup': function () {
      this.fetchData()
    },
    'hostCondition.loadTypeForm': function () {
      this.fetchData()
    },
    'hostCondition.groupAccount': function () {
      console.log('look up by groupAccount')
      this.fetchData()
    }
  },
  created() {
    this.organizedQueue()
    this.fetchRtTruckSpecific()
    this.fetchData()
    this.reRender()
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  methods: {
    organizedQueue() {
      try {
        const userData = getUserData()
        const role = userData.role ?? ''
        if (['host', 'security'].includes(role)) {
          this.disableViewLimit = false
          // if (this.statisticTitle === 'TEM') {
          useJwt.http.get(`form_limit/1`).then((response) => {
            const data = response.data.form_limits
            this.maxApprovement['TEM'] = data.group_limit
            this.currentApprovement['TEM'] = data.live_count
          })
          // }
          // if (this.statisticTitle === 'PATRA') {
          useJwt.http.get(`form_limit/2`).then((response) => {
            const data = response.data.form_limits
            this.maxApprovement['PATRA'] = data.group_limit
            this.currentApprovement['PATRA'] = data.live_count
          })
          // }
        } else {
          this.disableViewLimit = true
        }
      } catch (error) {
        console.log('errors', error)
      }
    },
    async reRender() {
      // this.componentKey.host += 1
      // this.componentKey.truck += 1
      // this.componentKey.boat += 1
      // this.componentKey.qrcode += 1
      // this.componentKey.statistic += 1
    },
    getSuggestionValue(suggestion) {
      if (this.hostCondition.groupAccount !== suggestion.item.name) {
        this.hostCondition.groupAccount = suggestion.item.name
        console.log('suggestion:', suggestion)
        this.fetchData()
      } else {
        console.log('already on suggestion:', suggestion)
      }
      return this.hostCondition.groupAccount
    },
    async fetchRtTruckSpecific() {
      this.rtTruckTodayLoading = true
      useJwt.http
        .get('dashboard/graph/analytic/rt/truck')
        .then((reports) => {
          const { patra, tem, pil, hulu, all } = reports.data.result
          console.log(reports)
          this.rtTruckTodayPatraCount = patra
          this.rtTruckTodayTemCount = tem
          this.rtTruckTodayPilCount = pil
          this.rtTruckTodayHuluCount = hulu
          this.rtTruckTodayALL = all
        })
        .finally(() => {
          this.rtTruckTodayLoading = false
        })
    },
    async fetchData() {
      this.loading = true
      await this.$http.get('/ecommerce/data').then(async (response) => {
        this.data = response.data

        const userData = getUserData()
        const group = userData.group?.name ?? ''
        const role = userData.role
        console.log('role', role)
        if (group === 'TEM') {
          this.disableViewTEM = false
          this.disableViewPATRA = true
        }
        if (group === 'PATRA') {
          this.disableViewTEM = true
          this.disableViewPATRA = false
        }
        if (role === 'host') {
          this.disableViewTEM = false
          this.disableViewPATRA = false
        }
        this.data.congratulations.name = userData.full_name

        const isHost =
          this.$can('manage', 'all') || this.$can('manage', 'qrcode')
        this.data.congratulations.isHost = isHost
        this.componentKey.host += 1

        const keyword =
          isHost === true
            ? this.hostCondition
            : {
                ignoreGroup: false
              }

        this.data.truck_in_area = 0
        this.data.truck_in_queue_area = 0

        useJwt.http
          .get('dashboard/land', { params: { ...keyword } })
          .then((reports) => {
            // nextTick(() => {
            const resData = reports.data
            const { truck } = resData.statistic
            const { labels, qrcode } = resData.analytic

            // statistic
            this.data.statisticsLandItems[0].title = truck.truck_total
            this.data.statisticsLandItems[1].title = `${truck.clock_in} ✓`
            this.data.statisticsLandItems[2].title = `${truck.clock_out} ✓`
            this.data.statisticsLandItems[3].title = `${truck.form_published} 🎯`

            const truckAn = resData.analytic.truck
            const truckAnInArea = truckAn.truck_capacity_in_area
            const truckAnInQueueArea = truckAn.truck_capacity_queue_area

            this.data.truck_in_area = `${truckAnInArea} / ${truckAnInArea}`
            this.data.truck_in_queue_area = `${truckAnInQueueArea} / ${truckAnInQueueArea}`

            this.data.statisticsOrder.series[0].categories = labels
            this.data.statisticsProfit.series[0].categories = labels
            this.data.revenue.categories = labels

            this.data.statisticsOrder.series[0].data =
              truckAn.truck_in_area_days_ago.value
            this.data.revenue.revenueReport.series = qrcode.qrcode_days_ago
            this.data.revenue.budgetChart.series = qrcode.qrcode_days_ago

            this.componentKey.truck += 1
            // this.componentKey.boat += 1
            this.componentKey.qrcode += 1
            this.componentKey.statistic += 1
            this.loading = false
            // })
          })
      })
    },
    fetchGroupData(keyword, uri) {
      if (keyword) {
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          useJwt.http
            .get(`${uri}${keyword}`)
            .then((response) => {
              this.groupAccountData = response.data.groups
            })
            .catch((e) => {
              //
            })
        }, 300)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.login-bg {
  background-size: 140%;
  background-position: center center;
  -webkit-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  box-sizing: border-box;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
